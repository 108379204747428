import React from 'react';
import langue2 from '../assets/Langues/2.svg';
import logo from '../assets/logo.png';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Langue = () => {
    return (
        <div className="container-langue">
            <div className="logo">
            <img src={logo} alt="TheAfterSchool.Club" />
            </div>
            <div className="langue-content-head">
                Société des langues : Language Club
            </div>
            <div className="langue-content-para">
            Have you ever had a friend or your colleague talk in a foreign language and you felt
like it was Dothraki? You can bid adieu to that, because this time you will be able
to grasp what the person says. Become a part of Société des langues to learn to
truly discover the beauty of languages and have fun while doing it.
Please note that we are not going to help you learn Dothraki to impress the Queen
of dragons. We’re going to help you learn French, German and Spanish.
            </div>
            <div className="langue-content-points">
            Languages : French/German/Spanish<br></br>
Duration : 3 months<br></br>
Frequency : 2-3 classes per week<br></br>
            </div>
            <div className="langue-content-button">
            <Link to="/contact"><Button><div className="langue-content-button-text">Contact Us</div></Button></Link>
            </div>
            <div className="svg2">
                <img src={langue2} alt="langue2" id="langue2"></img>
            </div>
        </div>
    );
}

export default Langue;
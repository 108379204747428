import { Button } from 'react-bootstrap';
import React, {Component} from 'react';
import Typical from 'react-typical';
import knight from '../assets/Knights/3.png';
import pirates from '../assets/Pirates/1.png';
import yoga from '../assets/Yoga/1.png';
import string from '../assets/String/1.png';
import lang from '../assets/Langues/1.png';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

var words = ['Yoga', 'Chess', 'Music', 'Langues', 'Maths'];

class Home extends Component {
    componentDidMount() {

        const script1 = document.createElement('script');
        script1.src="//js.hsforms.net/forms/v2-legacy.js";
        script1.type="text/javascript";
        script1.charset="utf-8";
        script1.defer = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src="//js.hs-scripts.net/forms/v2.js";
        script2.type="text/javascript";
        script2.charset="utf-8";
        script2.defer = true;
        document.body.appendChild(script2);
        

        const ch = document.getElementById('c');
const chView = new IntersectionObserver(callbackCh);

function callbackCh(entry) {
        if (entry[0].isIntersecting) {
            ch.style.opacity = "1";
            ch.style.transition = "4s";
          }
          else {
            ch.style.opacity = "0";
          }
        }
chView.observe(document.getElementById('c'));


const pi = document.getElementById('p');
const piView = new IntersectionObserver(callbackPi);

function callbackPi(entry) {
        if (entry[0].isIntersecting) {
            pi.style.opacity = "1";
            pi.style.transition = "4s";
          }
          else {
            pi.style.opacity = "0";
          }
        }
piView.observe(document.getElementById('p'));


const yo = document.getElementById('y');
const yoView = new IntersectionObserver(callbackYo);

function callbackYo(entry) {
        if (entry[0].isIntersecting) {
            yo.style.opacity = "1";
            yo.style.transition = "4s";
          }
          else {
            yo.style.opacity = "0";
          }
        }
yoView.observe(document.getElementById('y'));


const st = document.getElementById('s');
const stView = new IntersectionObserver(callbackSt);

function callbackSt(entry) {
        if (entry[0].isIntersecting) {
            st.style.opacity = "1";
            st.style.transition = "4s";
          }
          else {
            st.style.opacity = "0";
          }
        }
stView.observe(document.getElementById('s'));


const la = document.getElementById('l');
const laView = new IntersectionObserver(callbackLa);

function callbackLa(entry) {
        if (entry[0].isIntersecting) {
            la.style.opacity = "1";
            la.style.transition = "4s";
          }
          else {
            la.style.opacity = "0";
          }
        }
laView.observe(document.getElementById('l'));

    }
    render() {
    return (
        <>
            <div className="section">
                {/* <div><Particles
                    params={{
                        particles: {
                            color: 'black'
                        }
                    }} /></div> */}
                <div className="logo">
                    <img src={logo} alt="TheAfterSchool.Club" id="logo"  />
                </div>
                <div className="hitched">
                    <div className="changing-names">
                        Get hitched to <span>
                            <Typical loop={Infinity}
                                wrapper='b'
                                steps={
                                    [
                                        words[0],
                                        2000,
                                        words[1],
                                        2000,
                                        words[2],
                                        2000,
                                        words[3],
                                        2000,
                                        words[4],
                                        2000
                                    ]
                                }
                            />

                        </span>
                    </div>
                    <div className="tagline">
                        You know there is more to life than school. Join our community at HSR Layout.
                </div>
                    <div className="book-button">
                    <Link to="/contact"> <Button id="btn" size="lg">
                            <div className="button-text">Book A Free Class Now</div>
                        </Button></Link>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="svg">
                    <img src={knight} id="knight" alt="Knight" />
                </div>
                <div className="heading-chess animate" id="c">
                    Knights Of Square Table
            </div>
                <div className="subHeading-chess">
                    The Chess Club
            </div>
                <div className="text-chess">
                    An entire world in 64 squares
            </div>
                <div className="btn-chess">
                <Link to="/chess">
                    <Button id="btn-chess">
                       <div className="button-text-chess">Check it out,mate</div>
                    </Button>
                    </Link>
                </div>
            </div>
            <div className="section" >
                <div className="svg">
                    <img src={pirates} id="pirates" alt="pirates" />
                </div>
                <div className='heading-pirates animate' id="p">
                    Pi-rates
            </div>
                <div className="subHeading-pirates">
                    The Math Club
            </div>
                <div className="text-pirates">
                    I am right 98% of the time, the other 3% is when I have to solve a math problem.
            </div>
                <div className="btn-pirates">
                <Link to="/pirate">
                    <Button id="btn-pirates">
                   <div className="button-text-pirates">Click to Solve</div>
                    </Button>
                    </Link>
                </div>
            </div>
            <div className="section" >
                <div className="svg">
                    <img src={yoga} id="yoga" alt="yoga" />
                </div>
                <div className='heading-yoga animate' id="y">
                    Yoga o' Clock
            </div>
                <div className="subHeading-yoga">
                    The Yoga Club
            </div>
                <div className="text-yoga">
                    Yoga to do it, if not now, when
            </div>
                <div className="btn-yoga">
                <Link to="/yoga">
                    <Button id="btn-yoga">
                    <div className="button-text-yoga">Enquire within</div>
                    </Button>
                    </Link>
                </div>
            </div>
            <div className="section" >
                <div className="svg">
                    <img src={string} id="string" alt="string" />
                </div>
                <div className='heading-string animate' id="s">
                    String Theory
            </div>
                <div className="subHeading-string">
                    The Music Club
            </div>
                <div className="btn-string">
                <Link to="/string">
                    <Button id="btn-string">
                    <div className="button-text-string">More notes here</div>
                    </Button>
                    </Link>
                </div>
            </div>
            <div className="section" >
                <div className="svg-lang" id="lang-svg">
                    <img src={lang} id="lang" alt="lang" />
                </div>
                <div className='heading-lang animate' id="l">
                    Societes des langues
            </div>
                <div className="subHeading-lang">
                    The Language Club
            </div>
                <div className="text-lang">
                    Que fais-tu ici? Rejoignez le club apres lecole
            </div>
                <div className="btn-lang">
                <Link to="/langue">
                    <Button id="btn-lang">
                        <div className="button-text-lang">Click to translate</div>
                    </Button>
                    </Link>
                </div>
            </div>
        </>

    );
    }
}




export default Home;
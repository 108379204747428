import React from 'react';
import Pirate2 from '../assets/Pirates/2.svg';
import logo from '../assets/logo.png';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Pirate = () => {
    return (
        <div className="container-pirate">
            <div className="logo">
            <img src={logo} alt="TheAfterSchool.Club" />
            </div>
            <div className="pirate-content-head">
                Pirates :The Math club
            </div>
            <div className="pirate-content-para">
            A train is traveling at 72km/h from point A to point B. Simultaneously, another
train is traveling at 60km/h from point B to point A. The distance between point A
and point is B is 120km. Assuming both the trains left the same time of the day,
Identify the point at which both the trains reach Pi-rates : The math club. Come
aboard this voyage, as our pirates take upon a sea of problems.
After all, math is just about finding patterns and making connections.
            </div>
            <div className="pirate-content-points">
            Duration : 3 months<br></br>
            Frequency : 2-3 classes per week<br></br>
            </div>
            <div className="pirate-content-button">
            <Link to="/contact"><Button><div className="pirate-content-button-text">Contact Us</div></Button></Link>
            </div>
            <div className="svg2">
                <img src={Pirate2} alt="pirate2" id="pirate2"></img>
            </div>
        </div>
    );
}

export default Pirate;
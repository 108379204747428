import React from 'react';
import string2 from '../assets/String/2.svg';
import logo from '../assets/logo.png';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const String = () => {
    return (
        <div className="container-string">
            <div className="logo">
            <img src={logo} alt="TheAfterSchool.Club" />
            </div>
            <div className="string-content-head">
                String Theory : Music Club
            </div>
            <div className="string-content-para">
            We know ABCDE, but do you know EADGBE? There is a very thin line between
playing musical instruments and understanding music. Music is capable of making us
feel nearly or possibly all emotions that we experience in our lives. Treading on this
line of ‘string’ is where the real beauty lies. Come tread with us to get all the
‘string action’ that you will ever need.
We prefer all our “strings to be attached”.
            </div>
            <div className="string-content-points">
            Instruments : Guitar, Keyboard &amp; Harmonica<br></br>
Duration : 3 months<br></br>
Frequency : 2-3 classes per week<br></br>
            </div>
            <div className="string-content-button">
            <Link to="/contact"><Button><div className="string-content-button-text">Contact Us</div></Button></Link>
            </div>
            <div className="svg2">
                <img src={string2} alt="string2" id="string2"></img>
            </div>
        </div>
    );
}

export default String;
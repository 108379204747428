import React from 'react';
import knight2 from '../assets/Knights/2.svg';
import logo from '../assets/logo.png';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const Chess = () => {
    return (
        <div className="container-chess">
            <div className="logo">
            <img src={logo} alt="TheAfterSchool.Club" />
            </div>
            <div className="chess-content-head">
                Knights of Square Table : Chess Club
            </div>
            <div className="chess-content-para">
            We’ve all come across chess at some point in our lives. There are some who have
never played chess, some who play chess to kill time, some who have taken it as a
hobby and some have even made a living out of it. In Knights of Square table, we
try to look through a different lens, Imagine having a full-fledged army with a
variety of individual abilities at your disposal whose sole objective is to protect
your majesty.
Chess isn’t always competitive, Chess can also be beautiful.
            </div>
            <div className="chess-content-points">
            Duration : 3 months<br></br>
Frequency : 2-3 classes per week<br></br>
            </div>
            <div className="chess-content-button">
                <Link to="/contact"><Button><div className="chess-content-button-text">Contact Us</div></Button></Link>
            </div>
            <div className="svg2">
                <img src={knight2} alt="knight2" id="knight2"></img>
            </div>
            
        </div>
    );
}

export default Chess;
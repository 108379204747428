import React, { Component } from 'react';
import Home from './HomeComponent';
import Chess from './ChessComponent';
import Pirate from './PirateComponent';
import String from './StringComponent';
import Yoga from './YogaComponent';
import Langue from './LangueComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Contact from './ContactComponent';

class Main extends Component {
    render() {
        return (
            <div className="contain">
                <Switch>
                    <Route path="/home" component={() => <Home />} />
                    <Route path="/chess" component={() => <Chess />} />
                    <Route path="/yoga" component={() => <Yoga />} />
                    <Route path="/pirate" component={() => <Pirate />} />
                    <Route path="/string" component={() => <String />} />
                    <Route path="/langue" component={() => <Langue />} />
                    <Route path="/contact" component={() => <Contact />} />
                    <Redirect to="/home"></Redirect>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Main);
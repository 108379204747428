import React from 'react';
import HubspotForm from 'react-hubspot-form';

const Contact = (props) => {
    return (
        <div className="hubspot-form">
            <HubspotForm
                portalId='9456364'
                formId='68717046-a2b7-4a8b-a330-caf3a1110c7d'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
            />
        </div>
    );
}

export default Contact;